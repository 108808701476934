import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import StatsTable from '../../Common/StatsTable/StatsTable';
import Totals from '../../Common/Totals/Totals';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import './Reports.css';
import '../../Common/StatsTable/StatsTable.css';
import Loader from '../../Common/Loader/Loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';
import QRCodeModal from './QRCodeModal';



const Client = new Api();
const moment = require('moment-timezone');
class Reports extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading:true,
            browser : Utilities.getCurrentBrowser(),
            currentMonth:moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM'),
            goalKey:`all-${moment.tz(moment(), 'Asia/Kolkata').format('MM-YYYY')}`,
            startDate : moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM-DD'),
            endDate : moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM-DD'),
            money : 0.0,
            data : null,
            stats : null,
            orderItems : [],
            totalAmount:0.0,
            totalNetAmount:0.0,
        }
        this.timer = null;
        this.socket = null;
    }

    loadOrders = async ()=>{
        const {startDate, endDate } = this.state;
        let startDateString = moment.tz(startDate,'Asia/Kolkata').format('YYYY-MM-DD');
        let endDateString = moment.tz(endDate,'Asia/Kolkata').format('YYYY-MM-DD');

        this.setState({
            loading:true
        },()=>{
            Client.getSafdarjungQRSales(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf()).then((orderData)=>{
                let totalAmount = 0.0;
                if(orderData && orderData.items){
                    for(let i=0;i<orderData.items.length;i++){
                        if(orderData.items[i].status.toLowerCase() === "captured"){
                            totalAmount+= (orderData.items[i].amount/100.0);
                        }
                    }
                }
                this.setState({
                    orderItems:orderData.items,
                    totalAmount,
                    loading:false
                });
                
            }).catch((err)=>{
                console.log('GET GOT TEA DATA ERROR =>', err);
            });
        })
    }


    componentDidMount(){
        let mainThis = this;
        firebase.auth().onAuthStateChanged(user => {
            let loggedIn = !!user;
            if(loggedIn){
                this.loadOrders();
            } else {
                window.location.href = '/login';
            }
        });
        // Initialize WebSocket connection
        mainThis.socket = new WebSocket('wss://gottea-search-5gtrn.ondigitalocean.app/sales/ws');

        mainThis.socket.onopen = () => {
            console.log('WebSocket Connected');
        };

        mainThis.socket.onmessage = (event) => {
        // const message = JSON.parse(event.data);
        console.log('Message received:', event.data);
        mainThis.loadOrders();
        // if (message.data) {
        // // Assuming the message contains new payment data
        //     this.setState(prevState => ({
        //     payments: [...prevState.payments, message.data]
        //     }));
        // }
        };

        mainThis.socket.onerror = (error) => {
        console.error('WebSocket Error:', error);
        };

        mainThis.socket.onclose = () => {
        console.log('WebSocket Disconnected');
        this.setState({ ws: null }); // Clean up WebSocket connection
        };
    }

    componentWillUnmount(){
        if (this.socket) {
            this.socket.close();
        }
        clearInterval(this.timer);
    }

    setStartDate = (e) => {
        this.setState({startDate : e.target.value},()=>{
        });
    }

    onUpdateClick = ()=>{
        this.loadOrders();
    }

    setEndDate = (e) => {
        this.setState({endDate : e.target.value},()=>{
        });
    }

    render(){
        const { 
            orderItems,
            startDate, 
            endDate,
            totalAmount,
            loading,
         } = this.state;
        
     
        return <div className='reportContainer'>
            <ReportsHeader/>
            <div className='wrapper'>
                <div className='infoDiv'>
                { loading ? <Loader/> : <React.Fragment>
                    <div className='dateContainer'>
                    <input disabled={loading} type='date' className='dateInput' onChange={this.setStartDate} value={startDate}/>
                    <input disabled={loading} type='date' className='dateInput' onChange={this.setEndDate} value={endDate}/>
                    <div className='updateBtn' onClick={this.onUpdateClick}> 
                    <FontAwesomeIcon className='btnIcon' icon={faArrowCircleRight} size='1x'/>
                    </div>
                    </div>
                    <p className='moneyLarge'>₹{Utilities.formatIndianCurrency(totalAmount)}</p>
                    <QRCodeModal/>
                    </React.Fragment>}
            </div>
                <StatsTable loading={loading} title={'QR Sales Today'} showLargeAndSmall={true} data={orderItems}/>      
            </div>
            </div>
    } 
}

export default Reports;